import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/layout"

const IndexPage = props => {
  return (
    <Fragment>
      <Layout>
        <Img
          fadeIn={true}
          className="main-image-wrapper"
          fluid={props.data.homeImage.childImageSharp.fluid}
        />
        <h1>I'm Irvin,</h1>
        <h2>
          a&nbsp;
          <div className="subtitle-slider">
            <ul className="subtitle-slider-words">
              <li className="word">fullstack developer</li>
              <li className="word">software engineer</li>
              <li className="word">visual artist</li>
              <li className="word">fullstack developer</li>
            </ul>
          </div>
        </h2>
        <p>
          with first-class honors in Bachelor of Science in Computing,
          specializing in Software Engineering & Web Technologies. This website
          serves as a comprehensive portfolio that showcases my projects,
          encompassing both my previous industry works as an engineer and a
          collection of visually captivating artworks I have produced over the
          years as a content creator, photographer, and videographer.
          <br />
        </p>
        <div>
          <Link to="/portfolio">
            <button>Developer Portfolio</button>
          </Link>
          <Link to="/contact">
            <button>Get in Touch</button>
          </Link>
        </div>
      </Layout>
    </Fragment>
  )
}

export default IndexPage

export const homeImage = graphql`
  fragment homeImage on File {
    childImageSharp {
      fluid(maxWidth: 900) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const data = graphql`
  query {
    homeImage: file(relativePath: { eq: "media/main/home.jpg" }) {
      ...homeImage
    }
  }
`
